define("discourse/plugins/Moderatori Plugin/discourse/templates/connectors/before-create-topic-button/button-read-restricted", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (and this.category this.currentUser.admin) }}
  <ButtonEditReadRestriction
    @action={{this.toggleInfo}}
    @label="custom.edit_read_restricted"
    @id={{this.category.id}}
    @category_id={{this.category.id}}
    @icon="eye"
    class="btn-default"
  />
  {{/if}}
  
  */
  {
    "id": "lmJxVC/U",
    "block": "[[[41,[28,[37,1],[[30,0,[\"category\"]],[30,0,[\"currentUser\",\"admin\"]]],null],[[[8,[39,2],[[24,0,\"btn-default\"]],[[\"@action\",\"@label\",\"@id\",\"@category_id\",\"@icon\"],[[30,0,[\"toggleInfo\"]],\"custom.edit_read_restricted\",[30,0,[\"category\",\"id\"]],[30,0,[\"category\",\"id\"]],\"eye\"]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"and\",\"button-edit-read-restriction\"]]",
    "moduleName": "discourse/plugins/Moderatori Plugin/discourse/templates/connectors/before-create-topic-button/button-read-restricted.hbs",
    "isStrictMode": false
  });
});