define("discourse/plugins/Moderatori Plugin/discourse/helpers/check_moderatori", ["exports", "discourse-common/lib/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = checkModeratori;
  (0, _helpers.registerHelper)("check-moderatori", checkModeratori);
  function checkModeratori(args) {
    let groups = args[0];
    for (let i = 0; i < groups.length; i++) {
      if (groups[i]["name"] == "Moderatori") {
        return true;
      }
    }
    return false;
  }
});