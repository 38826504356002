define("discourse/plugins/Moderatori Plugin/discourse/templates/connectors/topic-footer-main-buttons-before-create/button-send", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (or (and this.topic (check-moderatori this.currentUser.groups)) (and this.topic this.currentUser.admin)) }}
  <CustomButton
    @action={{this.toggleInfo}}
    @label="custom.send_notification"
    @id={{this.topic.id}}
    @topic_id={{this.topic.id}}
    @icon="arrow-up"
    class="btn-default"
  />
  {{/if}}
  
  */
  {
    "id": "RTjtd2iC",
    "block": "[[[41,[28,[37,1],[[28,[37,2],[[30,0,[\"topic\"]],[28,[37,3],[[30,0,[\"currentUser\",\"groups\"]]],null]],null],[28,[37,2],[[30,0,[\"topic\"]],[30,0,[\"currentUser\",\"admin\"]]],null]],null],[[[8,[39,4],[[24,0,\"btn-default\"]],[[\"@action\",\"@label\",\"@id\",\"@topic_id\",\"@icon\"],[[30,0,[\"toggleInfo\"]],\"custom.send_notification\",[30,0,[\"topic\",\"id\"]],[30,0,[\"topic\",\"id\"]],\"arrow-up\"]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"or\",\"and\",\"check-moderatori\",\"custom-button\"]]",
    "moduleName": "discourse/plugins/Moderatori Plugin/discourse/templates/connectors/topic-footer-main-buttons-before-create/button-send.hbs",
    "isStrictMode": false
  });
});