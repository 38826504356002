define("discourse/plugins/Moderatori Plugin/discourse/controllers/admin-plugins-moderatori", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "discourse/lib/ajax"], function (_exports, _controller, _object, _tracking, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPLuginsModeratoriController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "showText", [_tracking.tracked], function () {
      return false;
    }))();
    #showText = (() => (dt7948.i(this, "showText"), void 0))();
    showTentacle() {
      this.showText = !this.showText;
      console.log("PLUGIN");
      (0, _ajax.ajax)("/moderatori", {}).then(uploads => console.log(uploads));
    }
    static #_2 = (() => dt7948.n(this.prototype, "showTentacle", [_object.action]))();
  }
  _exports.default = AdminPLuginsModeratoriController;
});